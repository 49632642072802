<template>
  <v-dialog v-model="dialog" width="75%" height="90%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        @click="dialog = true"
        :title="$t('editChannel')"
        small
      >
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>{{ $t('editChannel') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <channel-form v-model="channel" @save="onSave" @cancel="close" @delete="onDelete" />
      <v-spacer />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import channelForm from './form.vue'

export default {
  components: {
    channelForm
  },
  props: {
    channel: { type: Object, required: true },
  },
  data() {
    return {
      dialog: false,
      model: {}
    }
  },
  computed: {
    ...mapGetters('user', ['tenantId', 'userId'])
  },
  mounted() {
    this.model = {
      entityId: this.entityId,
      entityType: this.entityType
    }
  },
  methods: {
    close() {
      this.model = {}
      this.dialog = false
    },
    async onDelete() {
      this.close()
      this.$emit('deleted', this.model)
      this.model = {}
    },
    async onSave(entity) {
      this.close()
      this.$emit('saved', entity)
      this.model = {}
    }
  }
}
</script>

<style></style>
