<template>
  <div>
    <v-card flat tile>
      <v-card-text>
        <v-alert v-if="errors.length" type="error" :value="true">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </v-alert>
        <v-form ref="form" v-model="valid">
          <v-row no-gutters>
            <v-col v-if="isNew" cols="12">
              <member-lookup
                v-model="model.members"
                label="Members"
                :multiple="true"
              ></member-lookup>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                :label="`${$t('channelCustomName')}`"
                v-model="model.name"
                :rules="nameRules"
              ></v-text-field>

              <v-textarea
                name="description"
                :label="`${$t('description')}`"
                auto-grow
                v-model="model.description"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <confirm-dialog
          v-if="isDeletable"
          :title="$t('channelDeleteTitle')"
          :text="$t('channelDeleteText')"
          @confirm="onDelete"></confirm-dialog>
        <v-spacer></v-spacer>
        <v-btn plain color="default" @click="onCancel">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn tile large color="primary" @click="onSave" :disabled="!valid">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>

  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import MemberLookup from '@/components/member/lookup.vue'
import ConfirmDialog from '@/components/dialogs/confirmation'

export default {
  components: {
    ConfirmDialog,
    MemberLookup
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      errors: [],
      model: {},
      valid: false,
      useCustomName: false,
      customName: '',
      nameRules: [
        v => (!v) || (v && v.length <= 75) || this.$t('maxLength', { length: 75 })
      ]
    }
  },
  computed: {
    ...mapGetters('user', ['tenantId', 'userId']),
    isNew() {
      return this.model.id ? false : true
    },
    isDeletable() {
      if(this.isNew){
        return false
      }

      return !(this.model.entityId && this.model.entityType)
    }
  },
  mounted() {
    this.model = _.cloneDeep(this.value)
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.model = _.cloneDeep(newVal)
      } else {
        this.model = {}
      }
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    confirmDelete(){
      this.$refs.confirmDialog.open()
    },
    onDelete() {
      if(this.isDeletable){
        this.$store.dispatch('messaging/deleteChannel', {id: this.model.id})
        this.$emit('delete', this.model)
      }
    },
    async onSave() {
      this.$refs.form.validate()
      if (!this.valid) {
        return
      }

      try{

        let members = []

        if(!this.model.id){

          if(this.model.members && this.model.members.length > 0){

            members = this.model.members.map((m) => ({ id: m.id }))

            if(!this.model.members.find((m) => m.id == this.userId)) {
              members.push({ id: this.userId})
            }
          }
        }

        const payload = {
          tenantId: this.tenantId,
          ...this.model,
          ...{members: members.map((m) => m.id)}
        }

        if(!this.model.id){
          const entity = await this.$store.dispatch('messaging/addChannel', payload)
          this.$emit('save', { ...entity })
        }
        else{
          await this.$store.dispatch('messaging/editChannel', payload)
          this.$emit('save', { ...this.model })
        }

        this.model = {}
      }
      catch(e){
          console.log('save error', e)
          if(e.errors){
            this.errors.push(e.errors)
          }
          else{
            this.errors.push(e)
          }
      }
    }
  }
}
</script>
