import apiClient from '@/services/api/clients/api-client'
import configService from '@/services/config.service'

const path = `${configService.config.apiUrl}/entityLookup`

const client = apiClient.build(path)

client.getByShortId = shortId => {
  const url = `${path}/shortId/${shortId}`
  return client.__api.get(url).then(response => response.data)
}

client.getByEntityId = (entityId, entityType) => {
  const url = `${path}/${entityType}/${entityId}`
  return client.__api.get(url).then(response => response.data)
}

export default client
