<template>
  <div>
    <v-autocomplete
      v-model="selectedMembers"
      :items="members"
      autofocus
      dense
      hide-selected
      chips
      color="blue-grey lighten-2"
      item-text="name"
      return-object
      :label="label"
      :menu-props="{ closeOnClick: true,closeOnContentClick:true }"
      :multiple="multiple"
      @change="onChange"
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="remove(data.item)"
        >
          {{ data.item.name }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
            <v-list-item-subtitle v-html="data.item.userName"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { memberApi } from '@/services/api';

export default {
  props: {
    label: {
      type: String,
      default: 'Select Member',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    value: { type: Array, default: () => [] }
  },
  data() {
    return {
      loading: false,
      search: '',
      select: null,
      members: [],
      selectedMembers: [],

    };
  },
  watch: {
      value(newVal) {
        this.selectedMembers = [...newVal]
      },
      search (val) {
        val && val !== this.select && val.length > 3 && this.fetchMembers(val)
      },
  },
  mounted() {
    this.selectedMembers = [...this.value];
    this.fetchMembers();
  },
  methods: {
    fetchMembers() {
      this.loading = true;

      memberApi.get().then((response) => {
        this.members = response.data.results.map((m) => ({
          id: m.id,
          name: m.name,
          userName: m.userName,
          userCode: m.userCode,
        }));
        this.loading = false;
      });
    },
    onChange() {
      this.$emit('input', this.selectedMembers);
    },
    remove(member) {
      this.selectedMembers = this.selectedMembers.filter((m) => m.id !== member.id);
      this.$emit('input', [...this.selectedMembers]);
    },
  },
};
</script>
