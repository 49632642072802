import apiClient from '@/services/api/clients/api-client.js'
import configService from '@/services/config.service'

const basePath = `${configService.config.apiUrl}/messaging/`

const build = function(tenantId) {
  const path = `${basePath}${tenantId}/channel`

  const client = apiClient.build(path)

  client.fetchByEntity = entityId => {
    const url = `${path}/for/${entityId}`
    return client.get(url)
  }

  client.search = model => {
    const url = `${path}/search`
    return client.__api.post(url, model)
  }

  client.available = () => {
    return client.get(`${path}/available`)
  }

  return client
}

export default {
  build
}
