import AuthService from '@/services/auth.service.js'
import locationApi from '@/services/location'
import i18n, { selectedLocale } from '@/plugins/i18n'
import UserProfileService from '@/services/api/profile'

export default {
  namespaced: true,
  state: {
    user: null,
    locale: selectedLocale,
    settings: {},
    tenant: window.localStorage ? JSON.parse(window.localStorage.getItem('tenant')) : null,
    tokenUser: null
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setLocale(state, payload) {
      state.locale = payload
    },
    setLocation(state, payload) {
      state.location = payload
    },
    setSettings(state, payload) {
      state.settings = payload
    },
    setTenant(state, payload) {

      state.tenant = payload
      if(window.localStorage){
        window.localStorage.setItem('tenant', JSON.stringify(payload))
      }
    },
    setTokenUser(state, payload) {
      state.tokenUser = payload
    },
    disable2fa(state) {
      state.user.isTwoFactorEnabled = false
    },
    enable2fa(state) {
      state.user.isTwoFactorEnabled = true
    }
  },
  actions: {
    async setUser({ commit, state }) {
      const user = await AuthService.getUser()

      commit('setTokenUser', user)

      if(user && !user.expired){
        let profile = user.profile

        if(!profile.user){
          profile = await UserProfileService.fetchUserProfile(user.access_token)
        }

        commit('setUser', profile.user)

        if(state.tenant == null || profile.user.memberships.indexOf(state.tenant.tenantId) != -1){
          //fallback to default tenant if tenant is null or previous tenant is not in the list of memberships
          commit('setTenant', profile.tenant)
        }

        const settings = await UserProfileService.fetchSettings(user.access_token)
        commit('setSettings', settings)
      }
      else{
        commit('setUser', null)
      }
    },
    async setTenant({ commit }, payload) {
      commit('setTenant', payload)
    },
    async clearUser({ commit }) {
      commit('setTenant', null)
      commit('setUser',  null)
    },
    async setLocation({ commit }, payload) {
      if (payload) {
        commit('setLocation', payload)
        return
      }

      const browserLocation = await locationApi.browserLocation()
      if (browserLocation) {
        const { latitude, longitude } = browserLocation
        commit('setLocation', { latitude, longitude })
      } else {
        commit('setLocation', locationApi.defaultLocation())
      }
    },
    async setLocale({ commit }, locale) {
      i18n.locale = locale
      commit('setLocale', { locale: locale })
    },
    async disable2fa({ commit }) {
      commit('disable2fa')
    },
    async enable2fa({ commit }) {
      commit('enable2fa', true)
    },
    async logout({ commit }) {
      commit('setUser', null)
      commit('setTenant', null)
      commit('setTokenUser', null)
      await AuthService.logout()
    }
  },
  getters: {
    tenant: state => {
      return state.tenant || {}
    },
    user : state => {
      return state.user || {}
    },
    organization: state => {
      if (state.tenant) {
        return state.tenant
      }
      return {}
    },
    isInRole: state => role => {
      if (state.tenant && state.tenant.roles) {
        return state.tenant.roles.indexOf(role) > -1
      }
      return false
    },
    isAuthenticated: state => {
      return (
        state.user !== null &&
        !state.expired
      )
    },
    isReady: (state, getters) => {
      return getters.isAuthenticated && getters.tenantId !== null
    },
    userCode: state => {
      if (state.user) {
        return state.user?.userCode
      }
      return ''
    },
    userId: state => {
      if (state.user) {
        return state.user?.id
      }
      return ''
    },
    tenantId: state => {
      if (state.tenant) {
        return state.tenant?.tenantId
      }
    },
    location: state => {
      return state.location
    },
    locale: state => {
      if (state.locale) {
        return state.locale
      }

      if (state.user && state.user['locale']) {
        return state.user['locale']
      }

      return 'en'
    },
    expiresAt: state => {
      if(state.tokenUser?.expires_at){
        return new Date(state.tokenUser?.expires_at * 1000)
      }
      return null
    },
    hasMultipleTenants: state => {
      return state.user?.memberships?.length > 1
    },
    applicationName: state => {
      return state.settings?.applicationName
    },
    logoUrl: state => {
      return state.settings?.logoUrl
    },
    faviconUrl: state => {
      return state.settings?.faviconUrl
    },
    hasFeature: state => feature => {
      return state.settings?.features?.includes(feature)
    }
  }
}
