const Index = () => import('@/views/messaging/index.vue')
const ChannelDetail = () => import('@/views/messaging/detail.vue')
const Home = () => import('@/views/messaging/home.vue')

export default [
  {
    path: '/messaging',
    component: Index,
    meta: {
      authenticated: true
    },
    children: [
      {
        path: '',
        name: 'messaging-home',
        component: Home,
        meta: {
          authenticated: true
        },
      },
      {
        path: 'channel/:channelId',
        name: 'messaging-channel-detail',
        component: ChannelDetail,
        meta: {
          authenticated: true
        },
      }
    ]
  }
]
