
<template>
  <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
        <v-badge
          :content="members.length"
          color="grey"
          >
          <v-icon>mdi-account-multiple</v-icon>
        </v-badge>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item) in members"
          :key="item.id"
        >
          <v-list-item-title>{{ item.userName }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
</template>
<script>
export default {
  props:{
    members: { type: Array, default: () => [] },
  },
  data: () => ({

  }),
  }
</script>
