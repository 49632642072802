<template>
  <div v-if="model">
    <app-toolbar :crumbs="crumbs">
      <v-spacer></v-spacer>

      <v-btn plain @click="toggleChat()" class="d-none d-md-inline">
        <message-badge :id="materialId" />
      </v-btn>
    </app-toolbar>

    <v-row>
      <v-col :cols="layout.mainCols">
        <material-form
          v-model="model"
          :collection="collection"
          @cancel="onCancel"
          @delete="onDelete"
          @save="onSave"
        ></material-form>
      </v-col>
      <v-col v-if="layout.showChat" :cols="layout.chatCols">
        <channel-messages
          v-model="collection"
          detail-route="collection-messages"
          entity-type="collection"
          @close="toggleChat()"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import channelMessages from '@/views/messaging/channel'
import collectionApi from '@/services/api/collection'
import materialApiBuilder from '@/services/api/material'
import materialForm from './form'
import messageBadge from '@/messaging/components/message-badge'
import messageMixin from '@/components/mixins/messaging'

export default {
  mixins: [messageMixin],
  components: {
    appToolbar,
    channelMessages,
    materialForm,
    messageBadge
  },
  data() {
    return {
      collection: null,
      materialApi: null,
      model: null,
      layout: {
        chatCols: 6,
        mainCols: 12,
        showChat: false
      }
    }
  },
  computed: {
    crumbs() {
      const items = [
        {
          text: this.$t('collections'),
          to: { name: 'collections-index' },
          disabled: false
        }
      ]

      if (this.collection) {
        items.push({
          text: this.collection.shortId,
          to: {
            name: 'collection-detail',
            params: { collectionId: this.collectionId }
          },
          disabled: false
        })
      }

      items.push({ text: 'Material', disabled: true })

      if (this.model) {
        items.push({
          text: this.model.shortId,
          to: {
            name: 'material-detail',
            params: { materialId: this.model.id }
          },
          disabled: false
        })
      }

      return items
    },
    collectionId() {
      return this.$route.params.collectionId
    },
    materialId() {
      return this.$route.params.materialId
    }
  },
  async mounted() {
    this.materialApi = materialApiBuilder.build(this.collectionId)
    this.collection = await collectionApi.fetch(this.collectionId)
    this.model = await this.materialApi.fetch(this.materialId)
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    onCancel() {
      this.returnToCollection()
    },
    async onDelete() {
      await this.materialApi.delete(this.materialId)
      this.addMessage({
        message: `${this.$t('material')} '${this.model.name}' ${this.$t(
          'hasBeenDeleted'
        )}`
      })
      this.returnToCollection()
    },
    async onSave(item) {
      await this.materialApi.put(this.materialId, item)
      this.addMessage({
        message: `${this.$t('material')} '${item.name}' ${this.$t(
          'hasBeenUpdated'
        )}`
      })
      this.returnToCollection()
    },
    returnToCollection() {
      this.$router.push({
        name: 'collection-detail',
        params: { collectionId: this.collectionId }
      })
    },
    toggleChat() {
      this.layout.showChat = !this.layout.showChat
      this.layout.mainCols = this.layout.showChat ? 7 : 12
      this.layout.chatCols = this.layout.showChat ? 5 : 0
    }
  }
}
</script>

<style></style>
